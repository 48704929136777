import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import AboutMe from './components/AboutMe';
import Projects from './components/Projects';
import Blog from './components/Blog';
import Contact from './components/Contact';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import GlobalStyles from './styles/GlobalStyles';
import MentionlyticsSentiment from './components/projects/MentionlyticsSentiment';
import CarpoolingApp from './components/projects/CarpoolingApp';
import MonthlyPlaylists from './components/projects/SpotifyAPIProject';
import PrisonersOfGeography from './components/blog/PrisonersOfGeography';
import WeaponsOfMathDestruction from './components/blog/WeaponsOfMathDestruction';
import Mindfck from './components/blog/Mindfck';
import ChipWar from './components/blog/ChipWar';
import HowToLieWithStatistics from './components/blog/HowToLieWithStatistics';
import TheSixthExtinction from './components/blog/TheSixthExtinction';
import HowTheWorldReallyWorks from './components/blog/HowTheWorldReallyWorks';
import HappinessHypothesis from './components/blog/HappinessHypothesis';
import WhereGoodIdeasComeFrom from './components/blog/WhereGoodIdeasComeFrom';
import TheWorldForSale from './components/blog/TheWorldForSale';
import ZeroToOne from './components/blog/ZeroToOne';
import BbdaClustering from './components/projects/BbdaClustering';
import BbdaInteractiveDashboard from './components/projects/BbdaInteractiveDashboard';
import FavoriteDataScienceLibraries from './components/blog/FavoriteDataScienceLibraries';
import TalkingToStrangers from './components/blog/TalkingToStrangers';
import InvisibleWomen from './components/blog/InvisibleWomen';
import Outliers from './components/blog/Outliers';
import SpotifyPlaylistCreatorProject from './components/projects/SpotifyPlaylistCreatorProject';
import BbdaRegression from './components/projects/BbdaRegression';
import FooledByRandomness from './components/blog/FooledByRandomness';
import TheMythOfSisyphus from './components/blog/TheMythOfSisyphus';

// Import Firebase Analytics
import { analytics } from './firebaseConfig';
import { logEvent } from 'firebase/analytics';

// Import ScrollToTop component
import ScrollToTop from './components/utils/ScrollToTop';


console.log(
  "%cLOOKING AT MY CODE, ARE YOU?", 
  "color: white; font-size: 20px; background-color: black; font-weight: bold; padding: 4px;"
);

console.log(
  "%cAWESOME! DIG AROUND!", 
  "color: white; font-size: 20px; background-color: black; font-weight: bold; padding: 4px;"
);

function App() {
  useEffect(() => {
    // Log a page view event when the app loads
    logEvent(analytics, 'page_view');

    // Disable browser's default scroll restoration
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <GlobalStyles />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutMe />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/mentionlytics-sentiment" element={<MentionlyticsSentiment />} />
        <Route path="/projects/carpooling-app" element={<CarpoolingApp />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} /> 
        <Route path="/projects/monthly-playlists" element={<MonthlyPlaylists />} />
        <Route path="book-review/prisoners-of-geography" element={<PrisonersOfGeography />} />
        <Route path="book-review/weapons-of-math-destruction" element={<WeaponsOfMathDestruction />} />
        <Route path="book-review/mindfck" element={<Mindfck />} />
        <Route path="book-review/chip-war" element={<ChipWar />} />
        <Route path="book-review/how-to-lie-with-statistics" element={<HowToLieWithStatistics />} />
        <Route path="book-review/the-sixth-extinction" element={<TheSixthExtinction />} />
        <Route path="book-review/how-the-world-really-works" element={<HowTheWorldReallyWorks />} />
        <Route path="book-review/happiness-hypothesis" element={<HappinessHypothesis />} />
        <Route path="book-review/where-good-ideas-come-from" element={<WhereGoodIdeasComeFrom />} />
        <Route path="book-review/the-world-for-sale" element={<TheWorldForSale />} /> 
        <Route path="book-review/zero-to-one" element={<ZeroToOne />} />
        <Route path="/projects/bbda-clustering" element={<BbdaClustering />}  />
        <Route path="/projects/bbda-interactive-dashboard" element={<BbdaInteractiveDashboard />} />
        <Route path="article/favorite-data-science-libraries" element={<FavoriteDataScienceLibraries />} />
        <Route path="book-review/talking-to-strangers" element={<TalkingToStrangers />} />
        <Route path="book-review/invisible-women" element={<InvisibleWomen />} />
        <Route path="book-review/outliers" element={<Outliers />} />
        <Route path="/projects/psycho-playlists" element={<SpotifyPlaylistCreatorProject />} />
        <Route path="/projects/household-income-prediction" element={<BbdaRegression />} />
        <Route path="book-review/fooled-by-randomness" element={<FooledByRandomness />} />
        <Route path="book-review/the-myth-of-sisyphus" element={<TheMythOfSisyphus />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
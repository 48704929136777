import React from 'react';
import styled from 'styled-components';

// Main Container for the book review
const BookReviewContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2d3e2f; /* Dark green background */
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
`;

// Title styling
const Title = styled.h1`
  font-size: 2.5rem;
  color: #fff; /* White color */
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

// Author styling
const Author = styled.p`
  font-size: 1.2rem;
  color: #b0c4b1; /* Light green-gray */
  margin-bottom: 1rem;
  font-style: italic;
`;

// Cover Image styling
const CoverImage = styled.img`
  width: 100%;
  max-width: 400px;
  max-height: 600px;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
`;

// Date Published styling
const DatePublished = styled.p`
  font-size: 1rem;
  color: #a9bfa9; /* Subtle greenish color */
  margin-bottom: 2rem;
`;

// Content Section styling
const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  background-color: #1f2920; /* Deep green background */
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
`;

// Section Title styling
const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #fff; /* White color */
  border-bottom: 2px solid #fff; /* White border */
  padding-bottom: 0.5rem;
`;

// Section Content styling
const SectionContent = styled.div`
  font-size: 1rem;
  line-height: 1.8;
  color: #d0e0d0; /* Light green */
`;

// Quote styling
const Quote = styled.blockquote`
  font-style: italic;
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #2e4032; /* Softer green */
  border-left: 4px solid #fff;
  border-radius: 4px;
  color: #b0c4b1;
`;

// List Item styling
const ListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
`;

// Ordered List styling
const OrderedList = styled.ol`
  padding-left: 1rem; /* Adjust padding to align with the section title */
`;

// Tags Container styling
const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

// Tag styling
const Tag = styled.span`
  background-color: #000; /* Black background */
  color: #76c176; /* Bright green text */
  padding: 0.4rem 0.7rem;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const Button = styled.button`
  display: inline-block;
  margin: 2rem 0;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #2d3e2f; /* Dark green */
  background-color: #76c176; /* Bright green */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;

  &:hover {
    background-color: #4a704a; /* Slightly darker green */
    color: #fff;
  }
`;

export const FooledByRandomnessMetadata = {
  title: "Fooled by Randomness",
  description: "The Hidden Role of Chance in Life and in the Markets",
  tags: [
    'Probability', 'Decision Making', 'Risk', 'Uncertainty',
    'Behavioral Economics', 'Finance', 'Philosophy'
  ],
  author: "Nassim Nicholas Taleb",
  datePublished: "2024-11-18",
  category: "Book Review",
  path: "/book-review/fooled-by-randomness",
  coverImage: "/images/fooled_by_randomness.jpg", // Ensure this path is correct
};

const FooledByRandomness = () => {
  const metadata = FooledByRandomnessMetadata;

  return (
    <BookReviewContainer>
      <Title>{metadata.title}</Title>
      <Author>by {metadata.author}</Author>
      <CoverImage src={metadata.coverImage} alt={metadata.title} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>
      <TagContainer>
        {metadata.tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </TagContainer>
      <br />
      <br />

      <ContentSection>
        <SectionTitle>Summary</SectionTitle>
        <SectionContent>
          <p>
            "Fooled by Randomness" explores the profound and often overlooked role of randomness in our lives, particularly in the world of finance and decision-making. Nassim Nicholas Taleb challenges our perception of luck, highlighting how we often attribute success to skill while dismissing the role of chance.
          </p>
          <br />
          <p>
            Taleb uses real-world anecdotes, insights from behavioral economics, and philosophical musings to illustrate how randomness affects outcomes more than we might admit. He also warns against overconfidence and the dangers of misunderstanding probability.
          </p>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Key Themes or Concepts</SectionTitle>
        <SectionContent>
          <OrderedList>
            <ListItem><strong>Luck vs. Skill:</strong> The thin line between true expertise and random success.</ListItem>
            <ListItem><strong>Survivorship Bias:</strong> How we overlook failures while glorifying success stories.</ListItem>
            <ListItem><strong>Black Swans:</strong> Rare, unpredictable events with major consequences.</ListItem>
            <ListItem><strong>Overconfidence:</strong> The cognitive biases that lead to poor decisions.</ListItem>
            <ListItem><strong>Probability and Uncertainty:</strong> Tools to understand and navigate randomness.</ListItem>
          </OrderedList>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Favorite Quotes</SectionTitle>
        <SectionContent>
          <Quote>
            "We favor the visible, the embedded, the personal, the narrated, and the tangible; we scorn the abstract."
          </Quote>
          <Quote>
            "Mild success can be explainable by skills and labor. Wild success is attributable to variance."
          </Quote>
        </SectionContent>
      </ContentSection>

      {/* Personal Reflection Section */}
      <ContentSection>
        <SectionTitle>Personal Reflection</SectionTitle>
        <SectionContent>
          <p>
            Reading "Fooled by Randomness" was a real wake-up call. It made me rethink many things I took for granted, like why some people succeed while others don't. Taleb’s point about how often we mistake luck for skill really hit home. I started looking back on moments in my own life and realizing how many things I had chalked up to “effort” or “talent” were actually influenced by random chance. It’s a humbling thought, but also strangely comforting because it takes some of the pressure off always needing to have everything figured out.
          </p>
          <br />
          <p>
            The idea of "Black Swans" was another big eye-opener. I used to think I was pretty good at predicting how things might go, whether in work, school, or life in general. But this book made me realize how much we underestimate the unpredictable. It’s like a reminder that no matter how much you prepare, there’s always a curveball waiting. And instead of being scared of it, I’ve started thinking about how I can better adapt when things don’t go according to plan.
          </p>
          <br />
          <p>
            What I took away most from this book is the importance of seeing the bigger picture and being okay with uncertainty. Life isn’t a straight path, and success isn’t always something you can control. That used to be a scary thought for me, but now it feels freeing. Instead of obsessing over outcomes, I’m learning to focus on the process—and to be a little more grateful for the random moments that shape my journey.
          </p>
        </SectionContent>
      </ContentSection>

      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </BookReviewContainer>
  );
};

export default FooledByRandomness;
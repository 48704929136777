import React from 'react';
import styled from 'styled-components';

// Main Container for the book review
const BookReviewContainer = styled.div`
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8; /* Off-white background */
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
`;

// Title styling
const Title = styled.h1`
  font-size: 2.5rem;
  color: #000; /* Black color */
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

// Author styling
const Author = styled.p`
  font-size: 1.2rem;
  color: #555; /* Gray for subtle contrast */
  margin-bottom: 1rem;
  font-style: italic;
`;

// Cover Image styling
const CoverImage = styled.img`
  width: 100%;
  max-width: 400px;
  max-height: 600px;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
`;

// Date Published styling
const DatePublished = styled.p`
  font-size: 1rem;
  color: #888;
  margin-bottom: 2rem;
`;

// Content Section styling
const ContentSection = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  background-color: #ffffff; /* White */
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
`;

// Section Title styling
const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #000; /* Black */
  border-bottom: 2px solid #000; /* Black border */
  padding-bottom: 0.5rem;
`;

// Section Content styling
const SectionContent = styled.div`
  font-size: 1rem;
  line-height: 1.8;
  color: #333; /* Darker gray for text */
`;

// Quote styling
const Quote = styled.blockquote`
  font-style: italic;
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #f4f4f4; /* Light gray */
  border-left: 4px solid #000; /* Black border */
  border-radius: 4px;
  color: #555;
`;

// List Item styling
const ListItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
`;

// Ordered List styling
const OrderedList = styled.ol`
  padding-left: 1rem; /* Adjust padding to align with the section title */
`;

// Tags Container styling
const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

// Tag styling
const Tag = styled.span`
  background-color: #000; /* Black */
  color: #fff; /* White text */
  padding: 0.4rem 0.7rem;
  border-radius: 12px;
  font-size: 0.8rem;
`;

const Button = styled.button`
  display: inline-block;
  margin: 2rem 0;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: #f8f8f8; /* Off-white */
  background-color: #000; /* Black */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;

  &:hover {
    background-color: #333; /* Slightly lighter black */
    color: #fff;
  }
`;

export const TheMythOfSisyphusMetadata = {
  title: "The Myth of Sisyphus",
  description: "A philosophical exploration of the absurd and the search for meaning.",
  tags: [
    'Philosophy', 'Existentialism', 'Absurdism', 'Camus',
    'Meaning', 'Life', 'Literature'
  ],
  author: "Albert Camus",
  datePublished: "2024-12-23",
  category: "Book Review",
  path: "/book-review/the-myth-of-sisyphus",
  coverImage: "/images/the_myth_of_sisyphus.jpg", // Ensure this path is correct
};

const TheMythOfSisyphus = () => {
  const metadata = TheMythOfSisyphusMetadata;

  return (
    <BookReviewContainer>
      <Title>{metadata.title}</Title>
      <Author>by {metadata.author}</Author>
      <CoverImage src={metadata.coverImage} alt={metadata.title} />
      <DatePublished>Date Published: {metadata.datePublished}</DatePublished>
      <TagContainer>
        {metadata.tags.map((tag, index) => (
          <Tag key={index}>{tag}</Tag>
        ))}
      </TagContainer>
      <br />
      <br />

      <ContentSection>
        <SectionTitle>Summary</SectionTitle>
        <SectionContent>
          <p>
            "The Myth of Sisyphus" is a profound philosophical exploration of the human condition and the search for meaning in an absurd world. Albert Camus uses the myth of Sisyphus, a figure condemned to endlessly push a boulder uphill only for it to roll back down, as a metaphor for life’s struggles and the futility of human endeavors.
          </p>
          <br />
          <p>
            Camus challenges readers to confront the absurd and argues that we can find meaning through rebellion and embracing life itself, despite its lack of inherent purpose. The work is a cornerstone of existentialism and absurdism, offering profound insights into how we can live authentically in an indifferent universe.
          </p>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Key Themes or Concepts</SectionTitle>
        <SectionContent>
          <OrderedList>
            <ListItem><strong>The Absurd:</strong> The conflict between humanity’s desire for meaning and the silent indifference of the universe.</ListItem>
            <ListItem><strong>Revolt:</strong> Embracing life and its struggles without resignation or false hope.</ListItem>
            <ListItem><strong>Freedom:</strong> The liberation that comes from rejecting imposed meanings and embracing the absurd.</ListItem>
            <ListItem><strong>Authenticity:</strong> Living fully in the present without clinging to illusions of purpose.</ListItem>
            <ListItem><strong>The Human Condition:</strong> Exploring what it means to exist in a world without inherent meaning.</ListItem>
          </OrderedList>
        </SectionContent>
      </ContentSection>

      <ContentSection>
        <SectionTitle>Favorite Quotes</SectionTitle>
        <SectionContent>
          <Quote>
            "One must imagine Sisyphus happy."
          </Quote>
          <Quote>
            "There is but one truly serious philosophical problem, and that is suicide."
          </Quote>
        </SectionContent>
      </ContentSection>

      {/* Personal Reflection Section */}
      <ContentSection>
        <SectionTitle>Personal Reflection</SectionTitle>
        <SectionContent>
          <p>
            "The Myth of Sisyphus" left me with a deep sense of awe and introspection. The idea that life has no inherent meaning can feel unsettling at first, but Camus offers a powerful alternative: to embrace the absurd and live fully in spite of it. It’s a perspective that feels both liberating and challenging.
          </p>
          <br />
          <p>
            I found myself particularly struck by Camus’s emphasis on revolt—not as a form of rebellion against the universe, but as a commitment to living authentically. It’s about finding joy and purpose in the struggle itself, rather than in some far-off destination. This resonated with me deeply, as I’ve often found myself caught up in the search for "meaning" rather than appreciating the journey.
          </p>
          <br />
          <p>
            The metaphor of Sisyphus is both haunting and inspiring. It’s a reminder that our struggles, no matter how repetitive or seemingly futile, are what define us. Camus’s assertion that "one must imagine Sisyphus happy" challenges me to look at my own life with fresh eyes—to find happiness not in achieving, but in striving.
          </p>
          <br />
          <p>
            Ultimately, this book has reshaped the way I think about purpose, freedom, and what it means to truly live. It’s a reminder that even in a world devoid of answers, we have the power to create our own.
          </p>
        </SectionContent>
      </ContentSection>

      <Button onClick={() => window.history.back()}>Back to Blog</Button>
    </BookReviewContainer>
  );
};

export default TheMythOfSisyphus;